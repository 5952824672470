@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@font-face {
    font-family: 'moondesk';
    src: url('./assets/fonts/moondesk.eot?lrexg');
    src: url('./assets/fonts/moondesk.eot?lrexg#iefix') format('embedded-opentype'),
         url('./assets/fonts/moondesk.ttf?lrexg') format('truetype'),
         url('./assets/fonts/moondesk.woff?lrexg') format('woff'),
         url('./assets/fonts/moondesk.svg?lrexg#moondesk') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Swiss721BoldCondensedBT';
    src: url('/assets/fonts/Swiss721BoldCondensedBT.otf') format('opentype');
}

[class^="moonicon-"], [class*=" moonicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'moondesk' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.moonicon-home:before {
    content: "\e90d";
}
.moonicon-user:before {
    content: "\e90c";
}
.moonicon-agenda:before {
    content: "\e900";
}
.moonicon-file:before {
    content: "\e90a";
}
.moonicon-files:before {
    content: "\e90b";
}
.moonicon-chat:before {
    content: "\e905";
}
.moonicon-compare:before {
    content: "\e907";
}
.moonicon-download:before {
    content: "\e908";
}
.moonicon-draw:before {
    content: "\e909";
}
.moonicon-inwork:before {
    content: "\e90e";
}
.moonicon-check:before {
    content: "\e906";
}
.moonicon-arrow_up:before {
    content: "\e904";
}
.moonicon-arrow_down:before {
    content: "\e901";
}
.moonicon-arrow_left:before {
    content: "\e902";
}
.moonicon-arrow_right:before {
    content: "\e903";
}

@media print
{
.light-theme app{
    height: auto !important;
}
.light-theme{
    height: auto !important;
}
body {
    width: 920px !important;
    // overflow: visible;
    main{
        padding: 0 !important;
    }
    .support-button{
        visibility: hidden;
    }
    .navbar{
        visibility: hidden;
    }
    .download-pdf-button , .edit-button, .download-icon-attachment{
        visibility: hidden !important;
        height : 0px !important;
    }
    .download-icon-attachment{
        width: 0px;
    }
    .task-report-pdf{
        border: none !important;
        page-break-before: auto;
    }
}
mat-sidenav{ visibility: hidden;}

}

body {
    margin: 0;
}
